import React from "react"
import { SocialFollows } from "~/components/social"
import clsx from "clsx"

export const Footer = ({ ...props }) => {
  return (
    <footer
      className={clsx(
        "mt-auto",
        "py-5",
        "bg-footerBg dark:bg-dark-footerBg",
        "text-footerColor dark:text-dark-footerColor"
      )}
      {...props}
    >
      <div className="center-container">
        <div className={`text-center mb-3`}>
          © {new Date().getFullYear()} {process.env.GATSBY_FOOTER_BRANDING} | Built with <span style={{color: "", fontSize: "1.5rem", position: "relative", top: "0.025rem"}}>&hearts;</span> by{`   `}
          <a
            href={process.env.GATSBY_FOOTER_BY_BRANDING_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {process.env.GATSBY_FOOTER_BY_BRANDING}
          </a>
          {`  |  `}
          <a
            href={process.env.GATSBY_FOOTER_LEGAL_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal
          </a>
        </div>
        <SocialFollows />
      </div>
    </footer>
  )
}
